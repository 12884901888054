import React, { forwardRef } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const MaterialCommunityLinkedIn = forwardRef(
  (
    { fillColor = '#212121', ...props }: { fillColor?: string } & SvgProps,
    ref
  ) => (
    <Svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      ref={ref}
      {...props}
    >
      <Path
        d='M19 3a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h14m-.5 15.5v-5.3a3.26 3.26 0 00-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 011.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 001.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 00-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z'
        fill={fillColor}
      />
    </Svg>
  )
)

export default MaterialCommunityLinkedIn
